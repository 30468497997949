import React from 'react';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

const Section = ({
  showK, kLeft, dark, red, noPaddingBottom, children,
}) => (
  <div className={[
    styles.wrapper,
    showK ? styles.showK : null,
    dark ? styles.dark : null,
    red ? styles.red: null,
    noPaddingBottom ? styles.noSectionPaddingBottom: null,
    kLeft ? styles.kLeft : null,
  ].join(' ')}
  >
    {children}
  </div>
);

Section.propTypes = {
  showK: PropTypes.bool,
  kLeft: PropTypes.bool,
  dark: PropTypes.bool,
  red: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Section.defaultProps = {
  showK: false,
  kLeft: false,
  dark: false,
  red: false,
  noPaddingBottom: false,
};

export default Section;
