import React from "react";

import styles from './CalenderButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';

const CalenderButton = ({}) => {
    return(
        <div>
            
            <a href="https://www.jameda.de/kathrin-kostka/arzt-ernaehrungsmediziner/bochum">
                <div className={styles.appointmentbox}>
                    <FontAwesomeIcon className={styles.appointmenticon} icon={faCalendarCheck} />
                    <p className={styles.appointmentText}>Termin buchen</p>
                </div>
            </a>
            <div className={styles.appointmentSizeBuffer}></div>
        </div>
    )
}

export default CalenderButton