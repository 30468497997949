import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Navbar, NavbarBrand, Collapse, Nav, NavItem, NavbarToggler,
} from 'reactstrap';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import logo from '../../images/kostka_logo.png';
import CalenderButton from '../CalenderButton/CalenderButton';
import styles from './Header.module.scss';

const Header = ({ white }) => {
  const scrolledTop = 0;
  const [scrolled, setScrolled] = useState(scrolledTop);
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (document) {
      setScrolled(document.documentElement.scrollTop);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setScrolled(document.documentElement.scrollTop);
    window.document.addEventListener('scroll', handleScroll);

    return () => {
      window.document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const beWhite = white && scrolled < 600;

  return (
    <div>
      <Navbar className={[styles.wrapper, beWhite ? styles.white : styles.dark, scrolled >= 600 ? styles.scrolled : null].join(' ')}  expand="md">
        <NavbarBrand>
          <Link to="/">
            <img alt="Dr. Kathrin Kostka Bochum" className={styles.logo} src={logo} />
          </Link>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon className={beWhite ? styles.white : styles.dark} icon={faBars} />
        </NavbarToggler>
        <Collapse className={styles.dropdown} isOpen={isOpen} navbar>
          <div className={styles.arrow} />
          <Nav navbar className={styles.links}>
            <NavItem>
              <Link className={[styles.whiteLink, styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/">Start</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/leistungen/aesthetik">Ästhetik</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/leistungen/schmerz">Schmerz</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/leistungen/ernaehrung">Ernährung</Link>
            </NavItem>
            {/* <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/diagnostik">Diagnostik</Link>
            </NavItem> */}
            {/* <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/aktuelles">Aktuelles</Link>
            </NavItem> */}
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/kontakt">Kontakt</Link>
            </NavItem>
            {/*<NavItem>
              <Link
                style={{
                  background: '#98C6BC',
                  color: 'white',
                  marginLeft: 10,
                }}
                className={[styles.link].join(' ')}
                to="https://onlinetermine.zollsoft.de/patientenTermine.php?uniqueident=5fbe53e2056f0"
              >
                Termin vereinbaren

              </Link>
              </NavItem>*/}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  white: PropTypes.bool,
};

Header.defaultProps = {
  white: false,
};

export default Header;
