import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Navbar, NavbarBrand, Collapse, Nav, NavItem, NavbarToggler, Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import logo from '../../images/Logo_K_grau.png';
import styles from './HeaderModern.module.scss';

const Header = ({ white }) => {
  const scrolledTop = 0;
  const [scrolled, setScrolled] = useState(scrolledTop);
  const [isOpen, setIsOpen] = useState(false);
  const [schmerzDropdownIsOpen, setSchmerzDropdownIsOpen] = useState(false);
  const [ernaehrungDropdownIsOpen, setErnaehrungDropdownIsOpen] = useState(false);
  const [aesthetikDropdownIsOpen, setAesthetikDropdownIsOpen] = useState(false);

  const handleScroll = () => {
    if (document) {
      setScrolled(document.documentElement.scrollTop);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleSchmerzDropdownIsOpen = () => {
    setSchmerzDropdownIsOpen(!schmerzDropdownIsOpen);
  }

  const toggleErnaehrungDropdownIsOpen = () => {
    setErnaehrungDropdownIsOpen(!ernaehrungDropdownIsOpen);
  }

  const toggleAesthetikDropdownIsOpen = () => {
    setAesthetikDropdownIsOpen(!aesthetikDropdownIsOpen);
  }

  useEffect(() => {
    setScrolled(document.documentElement.scrollTop);
    window.document.addEventListener('scroll', handleScroll);

    return () => {
      window.document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const beWhite = white && scrolled < 600;

  return (
    <div>
        <Link to="/" className={styles.logolink}>
            <img alt="Dr. Kathrin Kostka Bochum" className={styles.logo} src={logo} />
        </Link>
      <div className={styles.headerSeparator}></div>
      <Navbar className={[styles.wrapper, beWhite ? styles.white : styles.dark, scrolled >= 600 ? styles.scrolled : null].join(' ')}  expand="md">
        <NavbarToggler onClick={toggle} className={styles.noOutline}>
          <FontAwesomeIcon className={styles.burgericon} icon={faBars} />
        </NavbarToggler>
        <Collapse className={styles.dropdown} isOpen={isOpen} navbar>
          {/*<div className={styles.arrow} />*/}
          <Nav navbar className={styles.links}>
            <NavItem>
              <Link className={[styles.whiteLink, styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/">Start</Link>
            </NavItem>
            {/*<NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/leistungen/schmerz">Schmerz</Link>
          </NavItem>*/}

            <Dropdown nav isOpen={aesthetikDropdownIsOpen} toggle={toggleAesthetikDropdownIsOpen} id={styles.modernDropdown}>
              <DropdownToggle nav caret>
                Ästhetik
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem><Link to="/leistungen/aesthetik">Übersicht</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><Link to="/behandlung/botox">Botox©</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/hyperhidrosis">Hyperhidrosis</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/filler">Hyaluronsäure Filler</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/mesotherapie-aesthetik">Mesotherapie</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/eigenblut-aesthetik">PRP Eigenblut</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/haarausfall">Haarausfall</Link></DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown nav isOpen={ernaehrungDropdownIsOpen} toggle={toggleErnaehrungDropdownIsOpen} id={styles.modernDropdown}>
              <DropdownToggle nav caret>
                Ernährung
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem><Link to="/leistungen/ernaehrung">Übersicht</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><Link to="/behandlung/ernaehrungsberatung">Ernährungsberatung</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/mikronaehrstofftherapie">Mikronährstofftherapie</Link></DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown nav isOpen={schmerzDropdownIsOpen} toggle={toggleSchmerzDropdownIsOpen} id={styles.modernDropdown}>
              <DropdownToggle nav caret>
                Schmerz
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem><Link to="/leistungen/schmerz">Übersicht</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><Link to="/behandlung/klassische-schmerztherapie">Schmerztherapie</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/mesotherapie-schmerzbehandlung" >Mesotherapie</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/prp-eigenblut-schmerzbehandlung">PRP Eigenblut</Link></DropdownItem>
                <DropdownItem><Link to="/behandlung/akupunktur">Akupunktur</Link></DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {/*<NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/leistungen/ernaehrung">Ernährung</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/leistungen/aesthetik">Ästhetik</Link>
            </NavItem>
        */}
            {/* <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/diagnostik">Diagnostik</Link>
            </NavItem> */}
            {/* <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/aktuelles">Aktuelles</Link>
            </NavItem> */}
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/kontakt">Kontakt</Link>
            </NavItem>
            {/*<NavItem>
              <Link
                style={{
                  background: '#98C6BC',
                  color: 'white',
                  marginLeft: 10,
                }}
                className={[styles.link].join(' ')}
                to="https://onlinetermine.zollsoft.de/patientenTermine.php?uniqueident=5fbe53e2056f0"
              >
                Termin vereinbaren

              </Link>
              </NavItem>*/}
          </Nav>
        </Collapse>
      </Navbar>
      <div className={styles.headerSeparator}>
      </div>
    </div>
  );
};

Header.propTypes = {
  white: PropTypes.bool,
};

Header.defaultProps = {
  white: false,
};

export default Header;
